import LinkCard from "../../components/LinkCard/LinkCard"
import css from "./Classfy.module.css"

const Classfy = () => (
  <div className={css.main}>
    <LinkCard
      src='/classfy.png'
      alt="Classfy - Profes"
      description="Acceso profesores"
      link="https://teacher.classfy.online/"
    />
    <LinkCard
      src='/classfy.png'
      alt="Classfy - Alumnos"
      description="Acceso alumnos"
      link="https://app.classfy.online/"
    />
    <LinkCard
      src='/classfy.png'
      alt="Classfy - Admin"
      description="Acceso administrativo"
      link="https://admin.classfy.online/"
    />
    <LinkCard
      src='/notion.png'
      alt="Classfy - Micrófono"
      description="Guía para solucionar problemas con el micrófono"
      link="https://mundoestudiante.notion.site/problemas-microfono"
    />
    <LinkCard
      src='/notion.png'
      alt="Classfy - Guia alumnos"
      description="Información detallada de la funcionalidad de alumnos"
      link="https://mundoestudiante.notion.site/plataforma-student"
    />
    <LinkCard
      src='/notion.png'
      alt="Classfy - Guia profesores"
      description="Guía para solucionar problemas con el micrófono"
      link="https://mundoestudiante.notion.site/plataforma-teacher"
    />
  </div>
)

  export default Classfy