import LinkCard from "../../components/LinkCard/LinkCard"
import css from "./Innovacion.module.css"

const Innovacion = () => (
  <div className={css.main}>
    <LinkCard
      src='/mundoestudiante-agora.png'
      alt="Ágora"
      badge="Pruebas"
      badgeType="warning"
      description="Gestión de alumnos"
      link="https://agora.pre.mundoestudiante.cloud"
    />
    <LinkCard
      src='/mundoestudiante-core.png'
      alt="Core"
      badge="Pruebas"
      badgeType="warning"
      description="Administración de la academia"
      link="https://core.pre.mundoestudiante.cloud"
    />
    <LinkCard
      src='/mundoestudiante-profes.png'
      alt="Profes"
      badge="Pruebas"
      badgeType="warning"
      description="Plataforma de profes"
      link="https://profes.pre.mundoestudiante.cloud"
    />
    <LinkCard
      src='/classfy.png'
      alt="Classfy - Profes"
      badge="Pruebas"
      badgeType="warning"
      description="Acceso profesores"
      link="https://teacher.classfy.me/"
    />
    <LinkCard
      src='/classfy.png'
      alt="Classfy - Alumnos"
      badge="Pruebas"
      badgeType="warning"
      description="Acceso alumnos"
      link="https://app.classfy.me/"
    />
    <LinkCard
      src='/classfy.png'
      alt="Classfy - Admin"
      badge="Pruebas"
      badgeType="warning"
      description="Acceso vista administrador"
      link="https://admin.classfy.me/"
    />
    <LinkCard
      src='/mundoestudiante-aula-virtual.png'
      alt="Aula virtual"
      description="Moodle con material docente propio"
      link="https://aulavirtual.mundoestudiante.com"
    />
  </div>
)

export default Innovacion