import LinkCard from "../../components/LinkCard/LinkCard"
import css from "./Empleados.module.css"

const Empleados = () => (
  <div className={css.main}>
    <LinkCard
      src='/mundoestudiante-firmas.png'
      alt="Firmas"
      description="Firma corporativa para el email"
      link="https://firma.mundoestudiante.cloud/"
    />
    <LinkCard
      src='/notion.png'
      alt="Empleados"
      description="Información corporativa"
      link="https://mundoestudiante.notion.site/documentacion-empleados"
    />
  </div>
)

export default Empleados