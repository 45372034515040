import LinkCard from "../../components/LinkCard/LinkCard"
import css from "./Mundoestudiante.module.css"

const Mundoestudiante = () => (
  <div className={css.main}>
    <LinkCard
      src='/mundoestudiante-agora.png'
      alt="Ágora"
      description="Gestión de leads y alumnos"
      link="https://agora.mundoestudiante.cloud"
    />
    <LinkCard
      src='/mundoestudiante-profes.png'
      alt="Profes"
      description="Portal para el equipo docente"
      link="https://core.mundoestudiante.cloud"
    />
    <LinkCard
      src='/mundoestudiante-core.png'
      alt="Core"
      description="Administración de mundoestudiante"
      link="https://core.mundoestudiante.cloud"
    />
    <LinkCard
      src='/mundoestudiante-brain.png'
      alt="Brain"
      description="Portal con el material docente"
      link="https://brain.mundoestudiante.cloud/"
    />
  </div>
)

export default Mundoestudiante